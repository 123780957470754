exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-notice-tsx": () => import("./../../../src/pages/privacy-notice.tsx" /* webpackChunkName: "component---src-pages-privacy-notice-tsx" */),
  "component---src-pages-product-rail-software-development-tsx": () => import("./../../../src/pages/product/rail-software-development.tsx" /* webpackChunkName: "component---src-pages-product-rail-software-development-tsx" */),
  "component---src-pages-product-smartrepay-tsx": () => import("./../../../src/pages/product/smartrepay.tsx" /* webpackChunkName: "component---src-pages-product-smartrepay-tsx" */),
  "component---src-pages-product-smarttis-tsx": () => import("./../../../src/pages/product/smarttis.tsx" /* webpackChunkName: "component---src-pages-product-smarttis-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-templates-case-study-listing-tsx": () => import("./../../../src/templates/case-study-listing.tsx" /* webpackChunkName: "component---src-templates-case-study-listing-tsx" */),
  "component---src-templates-case-study-post-tsx": () => import("./../../../src/templates/case-study-post.tsx" /* webpackChunkName: "component---src-templates-case-study-post-tsx" */),
  "component---src-templates-news-listing-tsx": () => import("./../../../src/templates/news-listing.tsx" /* webpackChunkName: "component---src-templates-news-listing-tsx" */),
  "component---src-templates-news-post-tsx": () => import("./../../../src/templates/news-post.tsx" /* webpackChunkName: "component---src-templates-news-post-tsx" */),
  "component---src-templates-resource-listing-tsx": () => import("./../../../src/templates/resource-listing.tsx" /* webpackChunkName: "component---src-templates-resource-listing-tsx" */),
  "component---src-templates-resource-post-tsx": () => import("./../../../src/templates/resource-post.tsx" /* webpackChunkName: "component---src-templates-resource-post-tsx" */)
}

